import React from "react";
import { Link, graphql } from "gatsby"
import { GatsbyImage, getSrc } from "gatsby-plugin-image";
import { GatsbySeo, ProductJsonLd, BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';
import { GetSiteMetadata } from 'hooks/GetSiteMetadata';
import { renderRichText } from "gatsby-source-contentful/rich-text"

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from '@material-ui/core/Typography';
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Breadcrumbs } from '@material-ui/core';
import Hidden from "@material-ui/core/Hidden";

// core components
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Accordion from "components/Accordion/Accordion.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import HeaderTemplate from "components/Header/HeaderTemplate.js";
import HeaderScentsy from "components/Header/HeaderScentsy.js";
import FooterTemplate from "components/Footer/FooterTemplate.js";

import RedirectModal from "components/Redirect/RedirectModal.js";
import SearchModal from 'components/Footer/Modals/SearchModal.js';
import ContactUsModal from 'components/Footer/Modals/ContactUsModal.js';

import productStyle from "assets/jss/views/productStyleCustom.js";

import useIsClient from 'hooks/useIsClient';

const useStyles = makeStyles(productStyle);

export const query = graphql`
  query ($imageName: String!, $productFamilySlug: String!, $fragranceSlug: String!) {
    contentfulProductFamily(slug: {eq: $productFamilySlug}) {
      name
      shortDescription
      richDescription {
        raw
      }
      slug
    }
    contentfulCategory(products: {elemMatch: {slug: {eq: $productFamilySlug}}}) {
      name
      slug
      bannerImageName
      richDescription {
        raw
      }
    }
    fragrancesCsv(slug: {eq: $fragranceSlug}) {
      fragranceFamily
      slug
      name
      shortDescription
    }
    site {
      siteMetadata {
        title
        author
        businessName
        pwsURL
        partyID
      }
    }
    productImage: imageSharp(resize: {originalName: {eq: $imageName}}) {
      gatsbyImageData(placeholder: BLURRED)
    }
    backgroundBannerImages: allImageSharp(filter: {resize: {src: {regex: "/bg_/"}}}) {
      edges {
        node {
          gatsbyImageData(quality: 50)
          resize {
            originalName
            src
          }
        }
      }
    }
    backgroundBannerImagesMobile: allImageSharp(filter: {resize: {src: {regex: "/bg_/"}}}) {
      edges {
        node {
          gatsbyImageData(width: 1200)
          resize {
            originalName
            src
          }
        }
      }
    }
  }
`

export default function ProductPage(props) {

  const { isClient } = useIsClient();
  if (isClient) {
    var isMobile = window.matchMedia('(max-width: 600px)').matches;
    var bannerImage;
    if (isMobile) {
      const backgroundBannerImageEdgeMobile = props.data.backgroundBannerImagesMobile.edges.find(
        image_edge => image_edge.node.resize.originalName === props.data.contentfulCategory.bannerImageName
      )
      bannerImage = getSrc(backgroundBannerImageEdgeMobile.node)
    } else {
      const backgroundBannerImageEdge = props.data.backgroundBannerImages.edges.find(
        image_edge => image_edge.node.resize.originalName === props.data.contentfulCategory.bannerImageName
      )
      bannerImage = getSrc(backgroundBannerImageEdge.node)
    }
  }

  const classes = useStyles();

  // define options for documentToReactComponents (json > richText)
  const options = {
    renderNode: {
      "embedded-asset-block": (node) => {
        const alt = node.data.target.fields.title['en-CA']
        const url = node.data.target.fields.file['en-CA'].url

        return <img alt={alt} src={url} />
      }
    }
  }

  // define the description of the product
  var productDescription = "";
  var seoDescription = "";
  var seoTitle = "";
  
  if (props.pageContext.name.includes("Scentsy")) {
    seoTitle = props.pageContext.name;
  } else {
    seoTitle = "Scentsy " + props.pageContext.name;
  }

  // // if the product has fragrance, use it's product family description
  if (props.pageContext.hasFragrance === "true") {
    seoDescription = props.data.fragrancesCsv.shortDescription + ". " +
      props.data.contentfulProductFamily.shortDescription;

  } else {
    seoDescription = props.pageContext.shortDescription + " Authentic Scentsy " +
      props.data.contentfulProductFamily.name;

  }
  
  productDescription = seoDescription;

  if (seoDescription.length > 170) {
    seoDescription = seoDescription.substring(0,167) + "...";

  }

 
  // if the product has a frangrance show the fragrance notes
  var fragranceDescription = ""
  var productDetails = ""
 
  if (props.pageContext.hasFragrance === "true") {
    fragranceDescription = (
      <>
        <b>Fragrance Notes:</b>
        <ul><em>{props.data.fragrancesCsv.shortDescription}</em></ul>
      </>
    );

   
    productDetails = (
      <table border="0">
        <tbody>
          <tr>
            <td>Fragrance:</td><td>{props.data.fragrancesCsv.name}</td>
          </tr>
          <tr>
            <td>Fragrance Family:</td><td>{props.data.fragrancesCsv.fragranceFamily}</td>
          </tr>
          <tr>
            <td>SKU:</td><td>{props.pageContext.sku}</td>
          </tr>
        </tbody>
      </table>
    );
    
  } else {
    productDetails = (
      <table border="0">
        <tbody>
          <tr>
            <td>SKU:</td><td>{props.pageContext.sku}</td>
          </tr>
        </tbody>
      </table>
    );

   
  }

  const usdFormattedPrice = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.pageContext.price_usd);
  const cadFormattedPrice = new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(props.pageContext.price);
  var seoPrice;
  var appendToH1;

  if (props.pageContext.locale.ogLanguageCode === "en_CA") {
    seoPrice = new Intl.NumberFormat('en-CA', { style: 'decimal', currency: 'CAD' }).format(props.pageContext.price);
    appendToH1 = "Scentsy Catalog Canada";
  } else {
    seoPrice = new Intl.NumberFormat('en-US', { style: 'decimal', currency: 'USD' }).format(props.pageContext.price_usd);
    appendToH1 = "Scentsy Catalog USA";
  }

  // populate SEO data
  const productSchema = {
    name: props.pageContext.name,
    desc: productDescription,
    metaTitle: seoTitle,
    metaDescription: seoDescription,
    price: seoPrice,
    sku: props.pageContext.sku,
    isDiscontinued: props.pageContext.isDiscontinued
  };

  var useStateRegion = "";
  if (props.pageContext.locale.path.includes("/usa")) {
    useStateRegion = "usd";
  } else {
    useStateRegion = "cad";
  }

  const [currency, setCurrency] = React.useState(useStateRegion);

  const handleChange = event => {
    setCurrency(event.target.value);
  };

  // customize URL for specific product families
  var pwsURL = props.data.site.siteMetadata.pwsURL + props.pageContext.url;
  if (props.data.contentfulProductFamily.slug === "scentsy-buddy" ||
    props.data.contentfulProductFamily.slug === "scentsy-bundles") {
        pwsURL = pwsURL +
              "&partyID=" + props.data.site.siteMetadata.partyID;
  } else {
        pwsURL = pwsURL + "?partyID=" + props.data.site.siteMetadata.partyID;
  }

  var productLDJSONAvailability = "InStock";
  // check if the item is discontinued or not and prepare content
  if (props.pageContext.isDiscontinued === "true") {
    productLDJSONAvailability = "Discontinued";
  }

  // for SEO path
  const { siteMetadata } = GetSiteMetadata();
  var path = props.location.pathname;
  if (path.includes("/usa")) {
    path = path.replace("/usa", "");
  }

  // is this page supposed to redirect?
  var redirectPage = false;
  if (props.path.includes("/go")) {
    redirectPage = true;
  }
  var seoJSX;
  var shoppingCartButtonJSX;
  // if this page is a redirect page, include refresh
  if (redirectPage) {
    if (path.includes("/go/")) {
      path = path.replace("/go/", "/");
    }
    seoJSX = (
      <>
        <GatsbySeo
          noindex={true}
          nofollow={true}
          title={productSchema.metaTitle + props.pageContext.locale.appendToTitle}
          description={productSchema.metaDescription}
          canonical={siteMetadata.siteUrl + props.pageContext.locale.path + path}
          language={props.pageContext.locale.isoLanguageCode}
          openGraph={{
            title: productSchema.metaTitle + props.pageContext.locale.appendToTitle,
            url: siteMetadata.siteUrl + props.pageContext.locale.path + path,
            locale: props.pageContext.locale.ogLanguageCode,
            images: [
              {
                url: siteMetadata.siteUrl + props.pageContext.image,
                alt: 'Picture of ' + productSchema.metaTitle,
                width: 600,
                height: 600
              }]
          }}
        />
        {/**<Helmet>
          <meta
            http-equiv="refresh"
            content={`0;url=${pwsURL}`}
          />
        </Helmet>**/}
      </>
    );
    shoppingCartButtonJSX = (
      <a href={pwsURL} target="_blank" rel="nofollow noreferrer" >
        <Button round color="primary" >
          Add to Cart &nbsp; <ShoppingCart />
        </Button>
      </a>
    );
  } else {
    seoJSX = (
      <>
        <GatsbySeo
          title={productSchema.metaTitle + props.pageContext.locale.appendToTitle}
          description={productSchema.metaDescription}
          canonical={siteMetadata.siteUrl + props.pageContext.locale.path + path}
          language={props.pageContext.locale.isoLanguageCode}
          languageAlternates={[
            {
              hrefLang: props.pageContext.locale.isoLanguageCode,
              href: siteMetadata.siteUrl + props.pageContext.locale.path + path,
            },
            {
              hrefLang: props.pageContext.altLocale.isoLanguageCode,
              href: siteMetadata.siteUrl + props.pageContext.altLocale.path + path,
          }
          ]}
          openGraph={{
            type: 'website',
            title: productSchema.metaTitle + props.pageContext.locale.appendToTitle,
            description: productSchema.metaDescription,
            url: siteMetadata.siteUrl + props.pageContext.locale.path + path,
            site_name: 'SCENTSY Online Store | Home Fragrance Biz',
            locale: props.pageContext.locale.ogLanguageCode,
            images: [
              {
                url: siteMetadata.siteUrl + props.pageContext.image,
                alt: 'Picture of ' + productSchema.metaTitle,
                width: 600,
                height: 600
              }]
          }}
        />
        <ProductJsonLd
          productName={productSchema.metaTitle}
          images={[
            siteMetadata.siteUrl + props.pageContext.image,
          ]}
          description={productSchema.metaDescription}
          brand='Scentsy'
          offers={{
            itemCondition: 'NewCondition',
            availability: productLDJSONAvailability,
            price: productSchema.price,
            priceCurrency: props.pageContext.locale.currency,
            priceValidUntil: '2022-11-05',
            url: siteMetadata.siteUrl + props.pageContext.locale.path + path,
            seller: {
              name: 'Home Fragrance Biz Inc.',
            },
          }}
          mpn={props.pageContext.sku}
          sku={props.pageContext.sku}
        />
        <BreadcrumbJsonLd
          itemListElements={[
            {
              position: 1,
              name: 'Home',
              item: 'https://homefragrancebiz.com',
            },
            {
              position: 2,
              name: 'SCENTSY catalog',
              item: siteMetadata.siteUrl + props.pageContext.locale.path + path,
            },
            {
              position: 3,
              name: props.data.contentfulCategory.name,
              item: props.pageContext.locale.path + '/shop/' + props.data.contentfulCategory.slug + '/',
            },
            {
              position: 4,
              name: props.data.contentfulProductFamily.name,
              item: props.pageContext.locale.path + '/shop/' + props.data.contentfulProductFamily.slug + '/',
            },
            {
              position: 5,
              name: props.pageContext.name,
            },
          ]}
        />
      </>
    );
    shoppingCartButtonJSX = (

      <RedirectModal
        destinationUrl={pwsURL}/>

    );
  }


       /** <a href={props.pageContext.locale.path + '/go/' + props.pageContext.slug} target="_blank" rel="nofollow noreferrer" >
        <Button round color="primary" >
          Add to Cart &nbsp; <ShoppingCart />
        </Button>
      </a>**/

  var productPriceContent = "";
  var productCurrencySelector = "";
  var productAddToCart = "";

  // check if the item is discontinued or not and prepare content
  if (props.pageContext.isDiscontinued === "true") {
    productPriceContent = (
      <>
        <Typography variant="h6" component="p" className={classes.mainPrice}>
          This item is discontinued.
        </Typography>
        <Typography variant="h5" component="p" className={classes.mainPrice}>
          Can we help you find something else?
        </Typography>
        <GridContainer>
        <GridItem xs={6}>
          <SearchModal />
        </GridItem>
        <GridItem xs={6}>
          <ContactUsModal />
        </GridItem>
      </GridContainer>
    </>
    );
    productAddToCart = (
      <></>
    );
  } else {
    productPriceContent = (
      <Typography variant="h4" component="p" className={classes.mainPrice}>
        {currency === 'usd' && usdFormattedPrice}
        {currency === 'cad' && cadFormattedPrice}
      </Typography>
    );
    productCurrencySelector = (
      <FormControl className={classes.formControl}>
        <InputLabel id="currency-label">Currency</InputLabel>
        <Select
          labelId="currency-select-label"
          id="currency-select-helper"
          value={currency}
          onChange={handleChange}
        >
          <MenuItem value={'cad'}>Canadian Dollars</MenuItem>
          <MenuItem value={'usd'}>US Dollars</MenuItem>
        </Select>
        <FormHelperText>Select preferred currency</FormHelperText>
      </FormControl>

    );
    productAddToCart = (
      <>
        <Hidden only={['sm','md','lg','xl']} implementation="js">
          <center>
          <GridContainer>
            <GridItem>
              {shoppingCartButtonJSX}
            </GridItem>
          </GridContainer>
          </center>
        </Hidden>
        <Hidden only={['xs']} implementation="js">
          <GridContainer style={{ float: "right" }}>
            <GridItem>
              {shoppingCartButtonJSX}
            </GridItem>
          </GridContainer>
        </Hidden>
      </>
    );
  }

  return (
    <div className={classes.productPage}>

      <HeaderTemplate
        locale={props.pageContext.locale}
        location={props.location}
      />

      {seoJSX}

      <Parallax
        image={bannerImage}
        filter="dark"
        small
        //style={{ paddingTop: "3%", height: "70vw", maxHeight: "500px" }}
      >
        <div className={classes.container}>
          <HeaderScentsy />
          <GridContainer>
            <GridItem
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <div className={classes.brand}>
                <Hidden only={['sm','md','lg','xl']} implementation="js">
                  <Typography variant="h3" component="h1" className={classes.title}>
                    Scentsy {props.pageContext.name}
                  </Typography>
                </Hidden>
                <Hidden only={['xs']} implementation="js">
                  <h1 className={classes.title}>
                    Scentsy {props.pageContext.name}
                  </h1>
                </Hidden>
                <Hidden only={['xs']} implementation="js">
                  <Typography variant="caption" component="p" className={classes.titleDescription}>
                    {productDescription}
                  </Typography>
                </Hidden>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <div className={classNames(classes.section, classes.sectionGray)}>
        <div className={classes.container}>
          <div className={classNames(classes.main, classes.mainRaised)}>

            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              <Typography variant="subtitle2" component="p">
                <Link color="inherit" to={props.pageContext.locale.path + '/shop/'} >
                  SHOP
                </Link>
              </Typography>
              <Typography variant="subtitle2" component="p">
                <Link color="inherit" to={props.pageContext.locale.path + '/shop/' + props.data.contentfulCategory.slug + "/" } >
                  {props.data.contentfulCategory.name.toUpperCase()}
                </Link>
              </Typography>
              <Typography variant="subtitle2" component="p">
                <Link color="inherit" to={props.pageContext.locale.path + '/shop/' + props.data.contentfulProductFamily.slug + "/" } >
                  {props.data.contentfulProductFamily.name.toUpperCase()}
                </Link>
              </Typography>
              <Typography color="textPrimary" variant="subtitle2" component="p">
                {props.pageContext.name.toUpperCase()}
              </Typography>
            </Breadcrumbs>

            <Hidden smDown implementation="js">
              <font color="#777777" size="1"><i> {appendToH1}</i></font>
            </Hidden>

            <hr></hr>

            <GridContainer>
              <GridItem md={6} sm={6}>
                <GatsbyImage
                  image={props.data.productImage.gatsbyImageData}
                  alt={'Picture of Scentsy ' + props.pageContext.name}
                  className="classes.imgFluid" />
              </GridItem>
              <GridItem md={6} sm={6}>

                <Hidden only={['sm','md','lg','xl']} implementation="js">
                  <Typography variant="h4" component="p" className={classes.productTitle}>
                    <center>
                      {props.pageContext.name}
                    </center>
                  </Typography>
                </Hidden>
                <Hidden only={['xs']} implementation="js">
                  <Typography variant="h4" component="p" className={classes.productTitle}>
                    {props.pageContext.name}
                  </Typography>
                </Hidden>

                <div>
                  <Hidden only={['sm','md','lg','xl']} implementation="js">
                    <center>
                      {productPriceContent}
                      {productCurrencySelector}
                    </center>
                  </Hidden>
                  <Hidden only={['xs']} implementation="js">
                    {productAddToCart}
                    {productPriceContent}
                    {productCurrencySelector}
                  </Hidden>
                </div>

                <Hidden only={['sm','md','lg','xl']} implementation="js">
                  {productAddToCart}
                </Hidden>

                <p></p>

                <Typography component="span">
                  {fragranceDescription}
                </Typography>
                <Typography variant="body1" component="h2">
                  {productDescription}
                </Typography>

                <hr />

                <Typography variant="body2" component="h3">
                  Learn more about this Scentsy product family
                </Typography>

                <Accordion
                  active={[0,1,2]}
                  activeColor="rose"
                  collapses={[
                    {
                      title: props.data.contentfulProductFamily.name,
                      content: (
                        <>
                          {renderRichText(props.data.contentfulProductFamily.richDescription, options)}
                        </>
                      )
                    },
                    {
                      title: props.data.contentfulCategory.name,
                      content: (
                        <>
                          {renderRichText(props.data.contentfulCategory.richDescription, options)}
                        </>
                      )
                    },
                    {
                      title: "Product Details",
                      content: (
                        <>
                          {productDetails}
                        </>
                      )
                    }
                  ]}
                />

                {productAddToCart}

              </GridItem>
            </GridContainer>
          </div>
          <div className={classNames(classes.features, classes.textCenter)}>
            <GridContainer>
              <GridItem md={4} sm={4}>
              </GridItem>
              <GridItem md={4} sm={4}>
                <InfoArea
                  title="30-day satisfaction guarantee"
                  description="If you are dissatisfied with your product, you may return it in resalable condition to your Independent Consultant for exchange within 30 days of the delivery date. Scentsy does not offer cash refunds."
                  icon={VerifiedUser}
                  iconColor="success"
                  vertical
                />
              </GridItem>
              <GridItem md={4} sm={4}>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>

      <FooterTemplate
        locale={props.pageContext.locale}
      />
       
    </div>
  );
}
