/*!

=========================================================
* Material Kit PRO React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Typography from '@material-ui/core/Typography';
import Hidden from "@material-ui/core/Hidden";

import PropTypes from "prop-types";

import { GetSiteMetadata } from 'hooks/GetSiteMetadata';

// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui icons
import People from '@material-ui/icons/People';
// core components
import CustomInput from 'components/CustomInput/CustomInput.js';

import style from "assets/jss/material-kit-pro-react/modalStyle.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(style);

function doRedirect(destinationUrl) {
  window.open(destinationUrl, '_blank')
}

export default function RedirectModal(props) {

  const classes = useStyles();

  const [redirectModal, setRedirectModal] = React.useState(false);

  //const initialExpiryTimestamp = new Date();

  // useTimer (React Timer Hook: https://github.com/amrlabib/react-timer-hook)
//  const {
//    seconds,
//    minutes,
//    hours,
//    days,
//    isRunning,
//    start,
//    pause,
//    resume,
//    restart,
//  } = useTimer({ autoStart: false, initialExpiryTimestamp, onExpire: () => { doRedirect(props.destinationUrl); setRedirectModal(false);} });

  function closeModal() {
    //pause();
    setRedirectModal(false);
  }

  return (
    <div>
      <Hidden smUp implementation="js">
        <Button round justIcon color="primary" onClick={
          () => {
              doRedirect(props.destinationUrl) ;
              setRedirectModal(true);
              //const expiryTimestamp = new Date();
              //expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 3);
              //restart(expiryTimestamp);
            }
          }
        ><ShoppingCart /></Button>
      </Hidden>
      <Hidden xsDown implementation="js">
        <Button round color="primary" onClick={
          () => {
                doRedirect(props.destinationUrl);
                setRedirectModal(true);
                //const expiryTimestamp = new Date();
                //expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 3);
                //restart(expiryTimestamp);
             }
          }
          >
          <ShoppingCart /> Add to Cart
        </Button>
      </Hidden>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal
        }}
        open={redirectModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeModal}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={closeModal}
          >
            {" "}
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}></h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
            <Typography variant="body2" component="p" style={{fontSize: "12px", color: "#777777"}}>
              In order to continue your transaction, you are being redirected to&nbsp;
              <a
                href={props.destinationUrl}
                target="_blank"
                rel="nofollow noreferrer"
              >
                homefragrancebiz.scentsy.ca
              </a>.
               If this does not work, please click&nbsp;
              <a
                 href={props.destinationUrl}
                 target="_blank"
                 rel="nofollow noreferrer"
               >this link.</a>
            </Typography>
             {/*<Typography variant="p" component="p" style={{fontSize: "10px", color: "#777777"}}>
              If you do not wish to continue, please close this dialog window.
            </Typography>*/}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            href={props.destinationUrl}
            target="_blank"
            rel="nofollow noreferrer"
            color="primary"
            >
            SHOP
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

RedirectModal.propTypes = {
  className: PropTypes.string,
  destinationUrl: PropTypes.string,
  style: PropTypes.string
};